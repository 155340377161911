import React, {useEffect} from "react";
import $ from "jquery";
import "./Cursor.scss";

export default function Cursor() {

    useEffect(() => {

        const mouseCursor = document.querySelector(".cursor");
        window.addEventListener("mousemove", cursor);


        function cursor(e) {
            mouseCursor.style.top = (e.pageY-window.scrollY) + "px";
            mouseCursor.style.left = e.pageX + "px";
        }


    }, [])



    return(
        <div className="cursor" onMouseDown={() => {
        }} onMouseMove={(e) => {
            const mouseCursor = document.querySelector(".cursor");
        }}>

        </div>
    )
}
