import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";

function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/"><LandingPage/></Route>
            </Switch>
        </Router>
    );
}

export default Routes;
