import React from 'react';
import Routes from "./Routes";
import Cursor from "./components/Cursor/Cursor";
import './App.css';
import Navbar from "./components/Navbar/Navbar";

function App() {

  return (
    <div>
        <Navbar/>
        <Cursor/>
        <Routes/>
    </div>
  );
}

export default App;
