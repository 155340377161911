import React, {useEffect, useState} from "react";
import $ from "jquery";
import "./LandingPage.scss";
import "./Intro.scss";
import iphone from "./iphone_mockupyellow.png";
import voodoo from "../../pages/Projects/assets/mockupbrowser.png";
import voodooqr from "../../pages/Projects/assets/testvoodo.png";
import {BsArrow90DegDown} from "react-icons/all";
import NewFooter from "../../components/Footer_/NewFooter";
import {Link} from "react-scroll";

export default function LandingPage() {

    const newest = [
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now',
        'launching now'
    ]

    useEffect(() => {
        document.querySelectorAll('*').forEach(el => {
            if (el.offsetWidth > document.documentElement.offsetWidth) {
                console.log('Found the worst element ever: ', el);
            }
        });
    })

    const [scrolled, setScrolled] = useState(false);
    const projects = document.getElementById('projects');
    const about = document.getElementById('about');

    useEffect(() => {
        checkScrolled();
    }, [])

    const checkScrolled = () => {
        console.log('body offset: ' + document.scrollingElement.scrollTop)
        if (document.scrollingElement.scrollTop > 200) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }

    $(window).scroll(function () {
        // selectors
        const $window = $(window),
            $body = $('body'),
            $panel = $('.panel');

        // Change 33% earlier than scroll position so colour is there when you arrive.
        const scroll = $window.scrollTop() + ($window.height() / 3);

        $panel.each(function () {
            const $this = $(this);

            // if position is within range of this panel.
            // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
            // Remember we set the scroll to 33% earlier in scroll var.
            if ($this.position().top <= scroll && $this.position().top + $this.height() > scroll) {

                // Remove all classes on body with color-
                $body.removeClass(function (index, css) {
                    return (css.match(/(^|\s)color-\S+/g) || []).join(' ');
                });

                // Add class of currently active div
                $body.addClass('color-' + $(this).data('color'));
            }
        });

    }).scroll();


    const openVoodoo = () => {
        window.open('https://thevoodoobrand.com')
    }

    return (
        <div id="portfolio">
            {scrolled &&
            <Link to="portfolio" spy={true} smooth={true} offset={0} duration={600}>
                <div onClick={() => setScrolled(false)} className="up-arrow">Take me up</div>
            </Link>
            }
            <div className="panel">
                <div></div>
                <div className="panel__first">
                    <div className="panel__big">
                        <h1 className="panel__first__title">Hi there,</h1>
                        <h1 className="panel__first__title">I am Ariadna
                        </h1>
                        <h3>But you can call me Ari. Welcome to my <span className="portfolio">portfolio</span>.</h3>
                    </div>
                    <div className="panel__small">
                            <h3>Developer</h3>
                            <h3>UX/UI Designer</h3>

                    </div>
                </div>
                <Link className="panel__arrow-container" activeClass="active" to="about" spy={true} smooth={true}
                      offset={0} duration={600}>
                    <div onClick={() => setScrolled(true)}><BsArrow90DegDown className="panel__arrow"/></div>
                </Link>
            </div>


            <div id="about" data-color="violet">
                <div className="about__first"></div>
                <div className="about__text">
                    <p>I am a <span className="custom-underline">front-end developer</span> based in Barcelona.</p>
                    <p>Passionate about design, graphic interfaces and coding.
                        I am also an Architect.</p>
                </div>
                <Link className="panel__arrow-container" activeClass="active" to="projects" spy={true} smooth={true}
                      duration={600}>
                    <div><BsArrow90DegDown className="panel__arrow"/></div>
                </Link>
            </div>

            <div id="projects" className="panel" data-color="indigo">

                <div className="projects__newest">
                    <div>
                        {newest.map((project, i) =>
                            <span>{project}</span>
                        )}
                    </div>
                </div>

                <div className="about__first"></div>
                <div className="projects__container">
                    <div>
                        <h1>The Voodoo Brand</h1>
                        <h2>Surfboards & Clothing</h2>
                    </div>

                    <div className="projects__voodoo__container">
                        <div className="projects__container__half info">
                            <h5>Resposive site</h5>
                            <img id="voodooqr" src={voodooqr} alt="https://www.thevodoobrand.com"/>
                            <a onClick={openVoodoo}>www.thevoodoobrand.com</a>
                        </div>
                        <div className="projects__container__half">
                            <img className="projects__voodoo" src={voodoo} alt="The Voodoo Brand"/>
                        </div>
                    </div>
                </div>

                <div></div>
                {/*<Link className="panel__arrow-container"  activeClass="active" to="projects" spy={true} smooth={true} duration={600}>*/}
                {/*    <div><BsArrow90DegDown className="panel__arrow"/></div>*/}
                {/*</Link>*/}
            </div>


            <NewFooter/>
        </div>
    )
}


