import React, {useEffect} from "react";
import {Link} from "react-scroll";
import logo from "./dots.svg";
import "./Navbar.scss";

export default function Navbar(props) {

    const wholeName = 'av';

    const sendEmail = () => {
        window.location.assign("mailto:ariadnavilarjimon@gmail.com")
    }

    useEffect(()=> {
        document.getElementById("myBar").style.width = 0;
        window.onscroll = function() {myFunction()};

        function myFunction() {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = (winScroll / height) * 100;
            document.getElementById("myBar").style.width = scrolled + "%";
        }
    })

    return (
        <div className="navbar">
            <div className="progress-container">
                <div className="progress-bar" id="myBar"></div>
            </div>
            <div className="navbar__beneath">
                {/*<Link to="portfolio" spy={true} smooth={true} offset={0} duration={600} >*/}
                {/*    <img className="navbar__title" src={logo} alt="Logo"/>*/}
                {/*</Link>*/}
                <div className="navbar__links">
                    <div>
                        <p onClick={sendEmail}>Contact</p>
                    </div>
                    <Link to="about" spy={true} smooth={true} offset={0} duration={600}>
                        <p>About</p>
                    </Link>
                    <Link to="projects" spy={true} smooth={true} offset={0} duration={600}>
                        <p>Projects</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}
