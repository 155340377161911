import React from "react";
import {AiOutlineCopyright} from "react-icons/all";
import "./NewFooter.scss";

export default function NewFooter() {

    const openSpotify = () => {
        window.open('https://open.spotify.com/user/somenoise?si=e9973fe508844829')
    }

    const openLinkedin = () => {
        window.open('https://www.linkedin.com/in/ariadnavilarjimon')
    }

    const openGithub = () => {
        window.open('https://github.com/ariadnavilar')
    }


    return (
        <div className="green-footer">
            <h3>Ariadna Vilar <span><AiOutlineCopyright/></span></h3>
            <div className="green-footer__link-container">
                <span onClick={openLinkedin}>linkedin</span>
                <span onClick={openGithub}>github</span>
                <span onClick={openSpotify}>spotify</span>
            </div>
        </div>
    )
}
